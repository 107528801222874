import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import GridLayout from "../../components/GridLayout"
import PageLayout from "../../components/PageLayout"
import GridTextProjectElement from "../../components/GridTextProjectElement"
import { projectNames } from "../../context/consts"
import Seo from "../../components/Seo"


const KefMu3Page = () => {

    const imageComponents = [
        <StaticImage key={0} src="./../../images/kefmu3/kefmu3-0.jpg" alt="" layout="fullWidth"></StaticImage>,
        <GridTextProjectElement key={1} projectName={projectNames.KEF_MU3} />,
        <StaticImage key={2} src="./../../images/kefmu3/kefmu3-1.jpg" alt="" layout="fullWidth"></StaticImage>,
        <StaticImage key={3} src="./../../images/kefmu3/kefmu3-2.jpg" alt="" layout="fullWidth"></StaticImage>,
        <StaticImage key={4} src="./../../images/kefmu3/kefmu3-3.jpg" alt="" layout="fullWidth"></StaticImage>,
        <StaticImage key={5} src="./../../images/kefmu3/kefmu3-4.jpg" alt="" layout="fullWidth"></StaticImage>,
    ]

    return (
        <PageLayout firstHead="kef" secondHead="inearmu3">
            <GridLayout components={imageComponents}></GridLayout>
            <Seo subTitle="kef inearmu3" />
        </PageLayout>
    )

}

export default KefMu3Page